import { post } from '@/utils/request';

// 回收统计-获取回收统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.recycle/getStatistical',
    data: data
});

// 回收统计-编辑回收统计表
const editStatistical = (data) => post({
    url: '/store/statistical.recycle/editStatistical',
    data: data
})


// 回收统计-新增回收统计表
const addStatistical = (data) => post({
    url: '/store/statistical.recycle/addStatistical',
    data: data
})

// 回收统计-获取回收统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.recycle/getStatisticalList',
    data: data
})

// 回收统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.recycle/totalStatisticalData',
    data: data
})

// 回收统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.recycle/totalStatisticalRadar',
    data: data
})

// 回收统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.recycle/totalStatisticalTime',
    data: data
})

// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.recycle/totalStatisticalList',
    data: data
})

const getAttrValueList = (data) => post({
    url: '/store/common.select/getAttrValueList',
    data: data
})

const getMaterialListReq = () => post({
    url: '/store/common.Select/getMaterialList',
});


// 导出
const downloadList = (data) => post({
    url: '/store/statistical.recycle/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.recycle/deleteStatistical',
    data: data
})

// 仓库
const getWarehouseListAll = (data) => post({
    url: '/store/goods.EntryCtl/getWarehouseListAll',
    data: data
})



export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    getTotalStatisticalList,
    getAttrValueList,
    downloadList,
    getMaterialListReq,
    deleteStatistical,
    getWarehouseListAll
}
